import React from "react";
import SEO from "../components/seo";
import Link from "../components/Link";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="container mx-auto">
      <div className="flex flex-col flex-grow mt-16 p-6 pt-16">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <div className="mt-12">
          <Link className="button " to="/">
            Go back
          </Link>
        </div>
      </div>
    </div>
  </>
);

export default NotFoundPage;
